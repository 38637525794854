<template>
  <!--{'pt-100':$route.path.indexOf('opt5') >= 0, 'pt-210': $route.path.indexOf('opt6') >=0, 'pt-250': $route.path.indexOf('opt2') >=0, 'pt-120': $route.path.indexOf('opt') < 0}-->
  <div id="cpn-home-popular">
    <h2 class="cond xs-my-20 xs-mx-10 xs-text-center heading-hr">
      <span>{{$t('home.button_our_most_popular_deals')}}</span>
    </h2>
    <div class="md-flex md-justify-center md-pb-10 lg-container banner-home">
      <a href="javascript: void(0)" @click="handleClickItem('left', left.url)"
         v-if="left && left.image.desktop" :style="right ? {
           maxWidth: '100%'
         }: {}"
         class="xs-block p10 decoration-none xs-full-width" data-synth="first-deal">
        <img v-bind:src="isMobile() ? left.image.mobile : left.image.desktop"
             data-synth="home-promo--5-favourite-single-5" class="xs-block xs-full-width" alt="">
        <button v-if="orderNow"
                class="order-now">
          {{ $t('order_now') }}
        </button>
        <div class="xs-text-center xs-text-gray-lightest xs-fill-gray-darkest xs-opacity-90 typo-l8"></div>
      </a>

      <a href="javascript: void(0)" @click="handleClickItem('right', right.url)"
         v-if="right && right.image.desktop"
         :style="left ? {
           maxWidth: '100%'
          } : {}"
         class="xs-block p10 decoration-none xs-full-width" data-synth="first-deal">

        <img v-bind:src="isMobile() ? right.image.mobile : right.image.desktop"
             data-synth="home-promo--5-favourite-single-5" class="xs-block xs-full-width" alt="">
        <button v-if="orderNow"
                class="order-now">
          {{ $t('order_now') }}
        </button>
        <div class="xs-text-center xs-text-gray-lightest xs-fill-gray-darkest xs-opacity-90 typo-l8"></div>
      </a>
    </div>
    <div class="xs-px-10 xs-mb-40 lg-container">

      <a href="javascript: void(0)" v-on:click.stop="handleClickItem('bottom')"
         class="button button--primary button--xl xs-mb-20 text-white-hover btn-view " id="btn-view-all-deal-element"
         data-synth="home-link--moredeals"
      ><span><span>{{ $t('home.button_see_all_deal')}} </span></span></a>

      <!-- <div class="home-coupon"
           v-on:click.stop="redirectToMenuPage($const.HEADER_MENU.COUPON)">
        <img src="https://static.phdvasia.com/mx/images/coupon-bg.jpg" class="bg"/>
        <div class="inner">
          <div class="text-wrap">
            <span>{{ $t('your_coupon') }}</span>
          </div>
          <a v-on:click.stop="redirectToMenuPage($const.HEADER_MENU.COUPON)">
            {{ $t('view_coupon') }}
          </a>
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>
import {
  mapGetters,
  mapActions
} from 'vuex'

export default {
  props: ['orderNow'],
  computed: {
    ...mapGetters({
      categoryList: 'categoryList'
    })
  },
  mounted () {
    this.getBanners()
  },
  data () {
    return {
      left: {
        image: {
          desktop: '',
          mobile: ''
        },
        url: ''
      },
      right: {
        url: '',
        image: {
          desktop: '',
          mobile: ''
        }
      }
    }
  },
  methods: {
    handleClickItem (type, url) {
      if (type != 'bottom' && !this.checkPosition(this)) {
        this.$router.push({
          'name': 'home',
          query: {
            checkDeals: true
          }
        })
      } else {
        let data = {
          id: '',
          moduleId: 'pop-home'
        }
        if (type === 'bottom') {
          data.id = 'all-home'
          data.moduleId = 'all-home-1'
          this.eventTrackingModuleItemClick(data)
        } else {
          if (type === 'left') {
            data.id = this.left.name.split(' ').join('-')
          } else if (type === 'right') {
            data.id = this.right.name.split(' ').join('-')
          }
          this.eventTrackingModuleItemClick(data)
        }

        if (url) {
          this.redirectToUrl(url, true)
        } else {
          this.redirectToPage(this.$const.VIEW_MENU_DEFAULT)
        }
      }
    },
    getBanners () {
      var vm = this
      this.axios.apiBanners.getBannersByPosition('?code=right_home,left_home,top_home_web', respone => {
        let data = respone.data.data.item
        let left_image = data.left_home
        let right_image = data.right_home
        const top_home = data.top_home_web
        if (top_home && top_home.length > 0) {
          const topHeader = document.getElementById('header-home-image')
          if (window.matchMedia('(max-width: 640px)').matches) {
            topHeader.style.backgroundImage = `url('${top_home[0].image.mobile}')`
          } else {
            topHeader.style.backgroundImage = `url('${top_home[0].image.desktop}')`
          }
        }
        if (typeof left_image !== 'undefined') {
          vm.left.image.desktop = left_image[0].image.desktop
          vm.left.image.mobile = left_image[0].image.mobile
          vm.left.url = left_image[0].url.desktop
          vm.left.name = typeof left_image[0].name === 'string' ? left_image[0].name : left_image[0].name[vm.$session.get('lang')]
        }
        if (typeof right_image !== 'undefined') {
          vm.right.image.desktop = right_image[0].image.desktop
          vm.right.image.mobile = right_image[0].image.mobile
          vm.right.url = right_image[0].url.desktop
          vm.right.name = typeof right_image[0].name === 'string' ? right_image[0].name : right_image[0].name[vm.$session.get('lang')]
        }
        let h = document.getElementById('btn-view-all-deal-element').offsetHeight
        let w = document.getElementById('btn-view-all-deal-element').offsetWidth
        let layer = {
          'modules': [],
          'assets': {
            'products': [],
            'promotions': [
              {
                'id': 'all-home-1',
                'name': 'view-all-deals',
                'creative': {
                  'id': 'all-home-1-creative',
                  'type': 'internal'
                },
                'attributes': {
                  'size': w + 'x' + h,
                  'destination': window.location.origin + '/order'
                }
              }
            ]
          }
        }
        let pop = {
          'id': 'pop-home',
          'name': 'most-popular-deals',
          'type': 'merchandising',
          'placement': 'home-promo-slots',
          'items': []
        }
        let prods = []
        if (vm.left.hasOwnProperty('name')) {
          let lname = vm.left.name.split(' ').join('-')
          pop.items.push({
            'id': lname,
            'type': 'product',
            'position': '1'
          })
          prods.push({
            'id': lname,
            'name': lname,
            'category': 'deal',
            'variants': [],
            'value': {},
            'tax': {
              'type': '',
              'name': '',
              'size': '0'
            },
            'purchaseMethod': 'main'
          })
        }
        if (vm.right.hasOwnProperty('name')) {
          let rname = vm.right.name.split(' ').join('-')
          pop.items.push({
            'id': rname,
            'type': 'product',
            'position': '2'
          })
          prods.push({
            'id': rname,
            'name': rname,
            'category': 'deal',
            'variants': [],
            'value': {
              'displayGross': '0',
              'displayTax': '0'
            },
            'tax': {
              'type': '',
              'name': '',
              'size': '0'
            },
            'purchaseMethod': 'main'
          })
        }
        layer.assets.products = prods
        layer.modules.push(pop)
        layer.modules.push({
          'id': 'all-home',
          'name': 'all-deals',
          'type': 'promotions',
          'placement': 'home-promo-bottom',
          'items': [
            {
              'id': 'all-home-1',
              'type': 'promotion',
              'position': '1'
            }
          ]
        })
        vm.$root.$emit('onTrackingHomepage', layer)
      }, error => {
        console.log('error', error)
      })
    }
  }
}

</script>
<style scoped>
  .pt-210 {
    padding-top: 210px !important;
  }

  .pt-120 {
    padding-top: 120px !important;
  }

</style>
